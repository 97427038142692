export const MAKE_VISIBLE = 'MAKE_VISIBLE';

export const WP_PLAY_CODE = 'WP_PLAY_CODE';
export const WP_STOP_CODE = 'WP_STOP_CODE';
export const WP_START_CODE_REFRESH = 'WP_START_CODE_REFRESH';
export const WP_STOP_CODE_REFRESH = 'WP_STOP_CODE_REFRESH';
export const WP_ADD_MEDIA_FILE = 'WP_ADD_MEDIA_FILE';
export const WP_ADD_FILE_TO_EDITOR = 'WP_ADD_FILE_TO_EDITOR';
export const WP_DELETE_FILE_FROM_EDITOR = 'WP_DELETE_FILE_FROM_EDITOR';
export const WP_UPDATE_CONSOLE_OUTPUT = 'WP_UPDATE_CONSOLE_OUTPUT';
export const WP_CLEAR_CONSOLE_OUTPUT = 'WP_CLEAR_CONSOLE_OUTPUT';
export const WP_UPDATE_FILE = 'WP_UPDATE_FILE';
export const WP_SET_CURRENT_FILE = 'WP_SET_CURRENT_FILE';
export const WP_SET_INNER_WIDTH = 'WP_SET_INNER_WIDTH';
export const WP_SET_INNER_HEIGHT = 'WP_SET_INNER_HEIGHT';
export const LOAD_WORKSPACE = 'LOAD_WORKSPACE';
export const TOGGLE_WORKSPACE = 'TOGGLE_WORKSPACE';
export const WP_SET_EDITOR_MODE = 'WP_SET_EDITOR_MODE';
export const OPEN_SHARE_WORKSPACE = 'OPEN_SHARE_WORKSPACE';
export const CLOSE_SHARE_WORKSPACE = 'CLOSE_SHARE_WORKSPACE';
export const WP_OPEN_FILE_VIEW = 'WP_OPEN_FILE_VIEW';
export const WP_CLOSE_FILE_VIEW = 'WP_CLOSE_FILE_VIEW';

export const SET_CURRENT_WIDGET = 'SET_CURRENT_WIDGET';
export const REMOVE_WIDGET = 'REMOVE_WIDGET';
export const CLOSE_DELETE_WIDGET_WARNING = 'CLOSE_DELETE_WIDGET_WARNING';
export const OPEN_DELETE_WIDGET_WARNING = 'OPEN_DELETE_WIDGET_WARNING';
export const DUPLICATE_WIDGET = 'DUPLICATE_WIDGET';
export const UPDATE_FILE = 'UPDATE_FILE';
export const TOGGLE_WIDGET_FULLSCREEN = 'TOGGLE_WIDGET_FULLSCREEN';

export const ADD_CODE_EDITOR = 'ADD_CODE_EDITOR';
export const ADD_MEDIA_FILE = 'ADD_MEDIA_FILE';
export const ADD_FILE_TO_EDITOR = 'ADD_FILE_TO_EDITOR';
export const DELETE_FILE_FROM_EDITOR = 'DELETE_FILE_FROM_EDITOR';
export const PLAY_CODE = 'PLAY_CODE';
export const STOP_CODE = 'STOP_CODE';
export const SET_INNER_WIDTH = 'SET_INNER_WIDTH';
export const SET_INNER_HEIGHT = 'SET_INNER_HEIGHT';
export const START_CODE_REFRESH = 'START_CODE_REFRESH';
export const STOP_CODE_REFRESH = 'STOP_CODE_REFRESH';
export const UPDATE_PYTHON_REPL_LINES = 'UPDATE_PYTHON_REPL_LINES';
export const RECIEVE_MESSAGE = 'RECIEVE_MESSAGE';
export const SET_CURRENT_FILE = 'SET_CURRENT_FILE';
export const OPEN_FILE_VIEW = 'OPEN_FILE_VIEW';
export const CLOSE_FILE_VIEW = 'CLOSE_FILE_VIEW';
export const VIEW_EDITOR_PREVIEW = 'VIEW_EDITOR_PREVIEW';
export const CLEAR_CONSOLE_OUTPUT = 'CLEAR_CONSOLE_OUTPUT';
export const UPDATE_CONSOLE_OUTPUT = 'UPDATE_CONSOLE_OUTPUT';
export const UPDATE_CONSOLE_OUTPUT_FOR_PYTHON = 'UPDATE_CONSOLE_OUTPUT_FOR_PYTHON';
export const ADD_WIDGET = 'ADD_WIDGET';
export const SET_EDITOR_VIEW = 'SET_EDITOR_VIEW';

export const ADD_TEXT_EDITOR = 'ADD_TEXT_EDITOR';
export const UPDATE_TEXT_CHANGE = 'UPDATE_TEXT_CHANGE';
export const UPDATE_TEXT_BACK_COLOR = 'UPDATE_TEXT_BACK_COLOR';
export const RESIZE_TEXT_EDITOR = 'RESIZE_TEXT_EDITOR';
export const UPDATE_TEXT_HEIGHT = 'UPDATE_TEXT_HEIGHT';

export const ADD_IFRAME = 'ADD_IFRAME';
export const SET_IFRAME_URL = 'SET_IFRAME_URL';
export const ADD_VIDEO = 'ADD_VIDEO';
export const SET_VIDEO_URL = 'SET_VIDEO_URL';
export const ADD_QUESTION_EDITOR = 'ADD_QUESTION_EDITOR';
export const SET_QUESTION_INNER_HEIGHT = 'SET_QUESTION_INNER_HEIGHT';
export const UPDATE_QUESTION_CHANGE = 'UPDATE_QUESTION_CHANGE';
export const UPDATE_ANSWER_CHANGE = 'UPDATE_ANSWER_CHANGE';

export const ADD_IMAGE = 'ADD_IMAGE';
export const SET_IMAGE_URL = 'SET_IMAGE_URL';
export const SET_IMAGE_CROP = 'SET_IMAGE_CROP';
export const RESET_IMAGE_CROP = 'RESET_IMAGE_CROP';

export const SET_PAGE_AUTHOR = 'SET_PAGE_AUTHOR';
export const SET_PARENT_PAGE_AUTHOR = 'SET_PARENT_PAGE_AUTHOR';
export const ADD_PAGE_TAG = 'ADD_PAGE_TAG';
export const DELETE_PAGE_TAG = 'DELETE_PAGE_TAG';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_PAGE_HEADING = 'SET_PAGE_HEADING';
export const SET_PAGE_DESCRIPTION = 'SET_PAGE_DESCRIPTION';
export const SUBMIT_PAGE = 'SUBMIT_PAGE';
export const CREATE_PAGE = 'CREATE_PAGE';
export const DUPLICATE_PAGE = 'DUPLICATE_PAGE';
export const RENAME_PAGE = 'RENAME_PAGE';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const SET_ALL_PAGES = 'SET_ALL_PAGES';
export const TRASH_PAGE = 'TRASH_PAGE';
export const SET_UNSAVED_CHANGES = 'SET_UNSAVED_CHANGES';
export const AUTO_SAVE_UNSAVED_CHANGES = 'AUTO_SAVE_UNSAVED_CHANGES';
export const PUBLISH_PAGE = 'PUBLISH_PAGE';
export const SET_PAGE_REMIXING_COMPLETED = 'SET_PAGE_REMIXING_COMPLETED';
export const SET_PAGE_REMIXING_STARTED = 'SET_PAGE_REMIXING_STARTED';
export const LOADING_PAGE_STARTED = 'LOADING_PAGE_STARTED';
export const LOADING_PAGE_COMPLETED = 'LOADING_PAGE_COMPLETED';

export const SET_DB_PAGE = 'SET_DB_PAGE';
export const SET_PAGE_ID = 'SET_PAGE_ID';
export const SET_PAGE_LAYOUT = 'SET_PAGE_LAYOUT';
export const SET_DB_EDITORS = 'SET_DB_EDITORS';
export const SET_DB_TEXT_EDITORS = 'SET_DB_TEXT_EDITORS';
export const TOGGLE_PREVIEW_MODE = 'TOGGLE_PREVIEW_MODE';
export const SET_PREVIEW_MODE = 'SET_PREVIEW_MODE';

export const SIGN_USER_UP = 'SIGN_USER_UP';
export const LOGIN_USER = 'LOGIN_USER';
export const SET_USER_NAME = 'SET_USER_NAME';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_USER_BROWSING_PEBL = 'SET_USER_BROWSING_PEBL';
export const MATCH_USER_NAME = 'MATCH_USER_NAME';
export const SET_EDIT_ACCESS = 'SET_EDIT_ACCESS';
export const SET_GUARDIAN_CONSENT = 'SET_GUARDIAN_CONSENT';
export const SET_GUARDIAN_EMAIL = 'SET_GUARDIAN_EMAIL';
export const SET_STUDENT_BIRTHDAY = 'SET_STUDENT_BIRTHDAY';
export const CLEAR_SIGNUP_VALUES = 'CLEAR_SIGNUP_VALUES';
export const SET_NEXT_SCREEN = 'SET_NEXT_SCREEN';

export const VIEW_ADD_DESCRIPTION_MODAL = 'VIEW_ADD_DESCRIPTION_MODAL';
export const CLOSE_ADD_DESCRIPTION_MODAL = 'CLOSE_ADD_DESCRIPTION_MODAL';
export const VIEW_EXAMPLES_MODAL = 'VIEW_EXAMPLES_MODAL';
export const CLOSE_EXAMPLES_MODAL = 'CLOSE_EXAMPLES_MODAL';
export const VIEW_FORK_PROMPT = 'VIEW_FORK_PROMPT';
export const CLOSE_FORK_PROMPT = 'CLOSE_FORK_PROMPT';
export const VIEW_PAGES_MODAL = 'VIEW_PAGES_MODAL';
export const CLOSE_PAGES_MODAL = 'CLOSE_PAGES_MODAL';
export const VIEW_SHARE_MODAL = 'VIEW_SHARE_MODAL';
export const CLOSE_SHARE_MODAL = 'CLOSE_SHARE_MODAL';
export const SET_SHARE_URL = 'SET_SHARE_URL';
export const VIEW_LOGIN_MODAL = 'VIEW_LOGIN_MODAL';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';
export const VIEW_LIVE_PAGE_REFRESH_MODAL = 'VIEW_LIVE_PAGE_REFRESH_MODAL';
export const CLOSE_LIVE_PAGE_REFRESH_MODAL = 'CLOSE_LIVE_PAGE_REFRESH_MODAL';
export const VIEW_SIGN_UP_MODAL = 'VIEW_SIGN_UP_MODAL';
export const CLOSE_SIGN_UP_MODAL = 'CLOSE_SIGN_UP_MODAL';
export const VIEW_FORGOT_MODAL = 'VIEW_FORGOT_MODAL';
export const CLOSE_FORGOT_MODAL = 'CLOSE_FORGOT_MODAL';
export const VIEW_RESET_MODAL = 'VIEW_RESET_MODAL';
export const CLOSE_RESET_MODAL = 'CLOSE_RESET_MODAL';
export const VIEW_CONFIRM_USER_MODAL = 'VIEW_CONFIRM_USER_MODAL';
export const CLOSE_CONFIRM_USER_MODAL = 'CLOSE_CONFIRM_USER_MODAL';
export const VIEW_WELCOME_MODAL = 'VIEW_WELCOME_MODAL';
export const CLOSE_WELCOME_MODAL = 'CLOSE_WELCOME_MODAL';
export const TOGGLE_PREFERENCES_PANEL = 'TOGGLE_PREFERENCES_PANEL';

export const TOGGLE_HELP_DROPDOWN = 'TOGGLE_HELP_DROPDOWN';
export const TOGGLE_FILE_DROPDOWN = 'TOGGLE_FILE_DROPDOWN';
export const TOGGLE_ACCOUNT_DROPDOWN = 'TOGGLE_ACCOUNT_DROPDOWN';

export const SET_USER = 'SET_USER';
export const SET_USER_LOADING = 'SET_USER_LOADING';
export const SET_USER_LOADING_COMPLETED = 'SET_USER_LOADING_COMPLETED';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_PEBLS = 'SET_PROFILE_PEBLS';
export const SET_PROFILE_FOLDERS = 'SET_PROFILE_FOLDERS';
export const SET_USER_IMAGE = 'SET_USER_IMAGE';
export const SET_USER_BLURB = 'SET_USER_BLURB';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_IS_OWNER = 'SET_IS_OWNER';

export const CREATE_FOLDER = 'CREATE_FOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const RENAME_FOLDER = 'RENAME_FOLDER';
export const UPDATE_FOLDER = 'UPDATE_FOLDER';
export const MOVE_PAGE_TO_TOP_LEVEL = 'MOVE_PAGE_TO_TOP_LEVEL';
export const MOVE_PAGE_TO_FOLDER = 'MOVE_PAGE_TO_FOLDER';
export const MOVE_FOLDER_TO_TOP_LEVEL = 'MOVE_FOLDER_TO_TOP_LEVEL';
export const MOVE_FOLDER_TO_FOLDER = 'MOVE_FOLDER_TO_FOLDER';
export const VIEW_FOLDER = 'VIEW_FOLDER';
export const VIEW_PAGE = 'VIEW_PAGE';
export const JUMP_TO_FOLDER = 'JUMP_TO_FOLDER';
export const CLEAR_SELECTED_FOLDERS = 'CLEAR_SELECTED_FOLDERS';

export const UPDATE_EDITOR_THEME = 'UPDATE_EDITOR_THEME';
export const UPDATE_EDITOR_FONT_SIZE = 'UPDATE_EDITOR_FONT_SIZE';
export const SET_USER_PREFERENCES = 'SET_USER_PREFERENCES';

export const CLOSE_NAVIGATION = 'CLOSE_N`AVIGATION';
export const CREATE_NAVIGATION_CONTENT = 'CREATE_NAVIGATION_CONTENT';
export const OPEN_NAVIGATION = 'OPEN_NAVIGATION';
export const SET_Y_NAVIGATION = 'SET_Y_NAVIGATION';
export const SET_Y_POSITION = 'SET_Y_POSITION';

export const LOAD_PAGE_VERSIONS = 'LOAD_PAGE_VERSIONS';
export const SHOW_OLD_PAGE_VERSION = 'SHOW_OLD_PAGE_VERSION';
export const HIDE_OLD_PAGE_VERSION = 'HIDE_OLD_PAGE_VERSION';
export const TOGGLE_PAGE_VERSION = 'TOGGLE_PAGE_VERSION';
export const SAVE_PAGE_VERSION = 'SAVE_PAGE_VERSION';
export const SET_UNSAVED_PAGE_VERSION = 'SET_UNSAVED_PAGE_VERSION';

/* * DASHBOARD * */
export const SET_DASHBOARD_VIEW = 'SET_DASHBOARD_VIEW';
export const SET_TRASH_PAGES = 'SET_TRASH_PAGES';
export const SET_DOCUMENT_VIEW = 'SET_DOCUMENT_VIEW';
export const SET_DOCUMENT_SORT = 'SET_DOCUMENT_SORT';
export const LOAD_MEMORY_CONSUMED = 'LOAD_MEMORY_CONSUMED';
export const SEARCH_BY_TITLE = 'SEARCH_BY_TITLE';
export const CLEAR_SEARCH_BY_TITLE = 'CLEAR_SEARCH_BY_TITLE';
export const SET_PARENT_FOLDER = 'SET_PARENT_FOLDER';
export const TOGGLE_ADD_NEW_MENU = 'TOGGLE_ADD_NEW_MENU';

export const FETCH_CLASSROOMS = 'FETCH_CLASSROOMS';
export const FETCH_CLASSROOM_CREATE_ACCESS = 'FETCH_CLASSROOM_CREATE_ACCESS';
export const ADD_CLASSROOM = 'ADD_CLASSROOM';
export const SET_SUBMITTING_DATA = 'SET_SUBMITTING_DATA';
export const TOGGLE_CREATE_CLASSROOM_MODAL = 'TOGGLE_CREATE_CLASSROOM_MODAL';
export const TOGGLE_LEARN_MORE_MODAL = 'TOGGLE_LEARN_MORE_MODAL';
export const TOGGLE_JOIN_CLASSROOM_MODAL = 'TOGGLE_JOIN_CLASSROOM_MODAL';
export const TOGGLE_CREATE_TOPIC_MODAL = 'TOGGLE_CREATE_TOPIC_MODAL';
export const TOGGLE_CREATE_ASSIGNMENT_MODAL = 'TOGGLE_CREATE_ASSIGNMENT_MODAL';
export const TOGGLE_CREATE_RESOURCE_MODAL = 'TOGGLE_CREATE_RESOURCE_MODAL';
export const TOGGLE_EDIT_TOPIC_MODAL = 'TOGGLE_EDIT_TOPIC_MODAL';
export const TOGGLE_ADD_MEMBER_MODAL = 'TOGGLE_ADD_MEMBER_MODAL';
export const SET_CURRENT_CLASSROOM = 'SET_CURRENT_CLASSROOM';
export const SET_CURRENT_ASSIGNMENT = 'SET_CURRENT_ASSIGNMENT';
export const CLEAR_CURRENT_CLASSROOM = 'CLEAR_CURRENT_CLASSROOM';
export const TOGGLE_DATA_LOADING = 'TOGGLE_DATA_LOADING';
export const SET_ASSIGNMENTS = 'SET_ASSIGNMENTS';
export const SET_TOPICS = 'SET_TOPICS';
export const SET_ASSIGNMENTS_PEOPLE = 'SET_ASSIGNMENTS_PEOPLE';
export const TOGGLE_EDIT_ASSIGNMENT_CONFIRMATION_MODAL = 'TOGGLE_EDIT_ASSIGNMENT_CONFIRMATION_MODAL';
export const TOGGLE_UNPUBLISH_ASSIGNMENT_CONFIRMATION_MODAL = 'TOGGLE_UNPUBLISH_ASSIGNMENT_CONFIRMATION_MODAL';
export const TOGGLE_EDIT_ASSIGNMENT_MODAL = 'TOGGLE_EDIT_ASSIGNMENT_MODAL';
export const SET_STUDENT_ASSIGNMENTS = 'SET_STUDENT_ASSIGNMENTS';
export const SET_ASSIGNMENT_ATTEMPTS = 'SET_ASSIGNMENT_ATTEMPTS';
export const SET_ASSIGNMENT_DRAG = 'SET_ASSIGNMENT_DRAG';
export const CHANGE_ASSIGNMENT_TOPIC = 'CHANGE_ASSIGNMENT_TOPIC';
